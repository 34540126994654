
import React from "react";
import styles from './../styles/covid.module.scss';
import prepYDeliveryImg from './../images/covid/preparacion-delivery.svg';
import empleadosImg from './../images/covid/empleados.svg';
import rappi from './../images/covid/Rappi.svg';
import pedidosya from './../images/covid/PedidosYa.svg';
import glovo from './../images/covid/GlovoGreen.svg';
import btnHome from './../images/covid/btnHome.svg';
import { Link } from "gatsby"
{/* */}

function covid(){
  return(
    <div className={styles.background}>
      <div className={styles.container}>
        <h1 className={styles.title}>ES MOMENTO DE CUIDARNOS ENTRE TODOS</h1>
        <h2 className={styles.firstText}>
          DESDE NUESTROS REFUGIOS Y LA MICROCERVECERÍA SEGUIMOS ESTRICTAMENTE LAS MEDIDAS IMPULSADAS
          POR EL MINISTERIO DE SALUD DE LA NACIÓN ARGENTINA JUNTO A NUESTROS ESTÁNDARES DE SEGURIDAD ALIMENTICIA: HACCP – ANÁLISIS DE PELIGROS Y PUNTOS CRÍTICOS DE CONTROL
        </h2>
        <h2 className={styles.secondText}>
          LAS MEDIDAS DE SALUD Y SEGURIDAD DE NUESTRO EQUIPO Y DE NUESTROS CLIENTES ES LO QUE MÁS NOS IMPORTA, POR ESO ESTUVIMOS TOMANDO DIFERENTES MEDIDAS DE PRECAUCIÓN, QUE IMPLICAN:
        </h2>
        <div className={styles.infoContainer}>
          <img className={styles.imgPreparacionyDelivery} src={prepYDeliveryImg} alt="PREPARACION Y DELIVERY"/>
          <h3 className={styles.textInfo}>
            HIGIENIZAMOS Y DESINFECTAMOS TODAS LAS ESTACIONES DE TRABAJO
            DURANTE LA PREPARACIÓN DE LOS PLATOS. <br/> <br/>

            PREPARAMOS LOS PEDIDOS CON BARBIJO Y GUANTES DE LATEX, QUE DESCARTAMOS LUEGO DE CADA USO. <br/> <br/>

            DESINFECTAMOS TODOS NUESTROS PRODUCTOS ENVASADOS ANTES DE SER COLOCADOS EN LAS BOLSAS DE ENTREGA. <br/> <br/>

            ENTREGAMOS EL PEDIDO EN UNA ESTACIÓN DE DESPACHO CERCANA A LA PUERTA DE INGRESO PARA EVITAR CUALQUIER TIPO DE CONTACTO CON EL EXTERIOR.  <br/>


            LA ESTACIÓN DE DESPACHO FUNCIONA COMO ZONA DE INTERCAMBIO SIN CONTACTO, APOYANDO EL PEDIDO EN LA MESA, MANTENIENDO LOS 2 METROS DE DISTANCIA Y DESINFECTANDO LAS SUPERFICIES LUEGO DE CADA ENTREGA. <br/> <br/>

            OFRECEMOS ALCOHOL EN GEL E INFORMACIÓN AL PERSONAL DE DELIVERY QUE LO REQUIERA, RESPETANDO SIEMPRE LAS MEDIDAS MÍNIMAS DE DISTANCIAMIENTO. <br/> <br/>

            RECOMENDAMOS EL MEDIO DE PAGO DIGITAL MEDIANTE LAS PLATAFORMAS, PARA EVITAR EL USO DE EFECTIVO. <br/> <br/>

            LUEGO DE RECIBIR EL PEDIDO, LO MÁS CONVENIENTE ES DESCARTAR EL ENVOLTORIO Y LAVARTE LAS MANOS CON ABUNDANTE AGUA Y JABÓN DURANTE 30 SEGUNDOS, ANTES Y DESPUÉS DE COMER.
          </h3>
          <img className={styles.imgEmpleados} src={empleadosImg} alt="EMPLEADOS"/>
          <h3 className={styles.textInfo}>
            REGISTRAMOS LA TEMPERATURA DEL PERSONAL ANTES Y DURANTE LA JORNADA LABORAL. <br/> <br/>

            UTILIZAMOS JABÓN ANTIBACTERIAL Y TOALLAS DE MANO PARA EL CORRECTO PROCEDIMIENTO DE LIMPIEZA DE MANOS. <br/> <br/>

            UTILIZAMOS GUANTES DE LATEX, BARBIJOS Y FAJINADOR PARA  TODAS LAS ESTACIONES DE TRABAJO QUE ASÍ LO REQUIERAN. <br/> <br/>

            SOLO ASISTIRÁN A TRABAJAR QUIENES NO TENGAN QUE USAR TRANSPORTE PÚBLICO Y QUE NO ESTÉN A CARGO DE ADULTOS, NIÑOS O PERSONAS CON DISCAPACIDAD.
          </h3>
        </div>
        <div className={styles.hashtags}>
          <h4 className={styles.textHastaghs}>#YOMEQUEDOENCASA</h4>
          <h4 className={styles.textHastaghs2}>#TUCASATUREFUGIO</h4>
        </div>
        <div className={styles.btnHome}>
          <Link to={'/'}>
            <img className={styles} src={btnHome} alt="VOLVER AL HOME"/>
          </Link>
        </div>
        <div className={styles.footer}>
          <a href="http://www.rappi.com.ar" target="_blank" rel="noreferrer"><img className={styles.deliveries} src={rappi} alt="RAPPI"/></a>
          <a href="http://www.pedidosya.com.ar" target="_blank" rel="noreferrer"><img className={styles.deliveries} src={pedidosya} alt="PEDIDOSYA"/></a>
          <a href="http://www.glovo.com.ar" target="_blank" rel="noreferrer"><img className={styles.deliveriesGlovo} src={glovo} alt="GLOVO"/></a>
        </div>
      </div>
    </div>
  )
}

export default (covid);